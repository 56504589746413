import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { sendPasswordResetMail } from "../helpers/auth";
import { Message } from "../../components/Message";
import { ContentWrapper } from "../../components/Content/Wrapper";

/**
 * PASSWORD FORGET & NEW ACCOUNT CHECKEN!
 */
export function AppAuthForgot() {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [error, setError] = useState();
    const { register, handleSubmit } = useForm();

    const onSubmit = data => {

        setError(null);

        sendPasswordResetMail(data).then((res) => {

            if (res.data.status === 'handled') {
                setIsSubmitted(true);
            }
            else {
                setError('Er is iets foutgegaan met het verzenden van uw verzoek (1)');
            }
        })
            .catch((error) => {
                console.log(error);
                setError('Er is iets foutgegaan met het verzenden van uw verzoek (2)');
            });
    }

    const forgotForm = () => {
        return (
            <div className="outline-box text-component">

                <Message type="error" message={error} />

                <h3>Wachtwoord vergeten</h3>
                <div id="result" style={{ color: 'red' }}></div>
                <form className="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form__row">
                        <div className="form__component">
                            <label htmlFor="emailaddress" className="form__label">
                                Gebruikersnaam of e-mail
                            </label>
                            <div className="form__element">
                                <input
                                    id="emailaddress"
                                    placeholder="Je emailadres"
                                    type="text"
                                    className={"form-control"} {...register("userName", { required: true })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form__row form__row--buttons">
                        <div className="form__component">
                            <div className="form__element">
                                <button type="submit" className={"button button--primary"}>
                                    <span className={"me-1"}></span>
                                    Versturen
                                </button>
                            </div>
                        </div>
                        <div className="form__component">
                            <div className="form__element">
                                <Link to={"/auth/login"} className={"button button--readmore"}>
                                    <span className={"read-more"}></span>
                                    Terug naar inloggen
                                </Link>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }

    const pageResponse = () => {
        return (
            <div className="component">
                <div className="outline-box outline-box--details" data-match="">

                    <Message type="success" message={"Wachtwoord herstel verzoek verzonden"} />
                    <p>Indien uw gebruikersnaam bekend is ontvangt u binnen enkele minuten een e-mail waarmee u uw wachtwoord kunt resetten.</p>
                    <Link to={"/auth/login"} className={"button button--readmore"}>
                        <span className={"read-more"}></span>
                        Terug naar inloggen
                    </Link>
                </div>
            </div>
        );
    }

    let content = isSubmitted === true ? pageResponse() : forgotForm();

    return (
        <ContentWrapper
            type="centred"
            content={content}
            className="outline-box--form"
        />
    );
}