import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppTransportDetailsStop } from "./Details/Stop";
import { ValidateResponse } from "../helpers/global";
import { fetchTransportDetails } from "../helpers/transport";
import { Message } from "../../components/Message";
import { Details } from "../../components/Details";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppTransportDetails({ transportId, setPageTitle, parents }) {

    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
        fetchTransportDetails(transportId).then((res) => {

            const response = ValidateResponse(res.data, 'details');

            if (response.status === 'error') {
                setError(response.message);
            }
            else {
                setState(response.details);
                setIsLoaded(true);
                setPageTitle(response.details.title);
            }
        }).catch((error) => {
            const response = ValidateResponse(error);
            setError(response.message);
        })
    }, [transportId, setError, setState, setIsLoaded, setPageTitle, navigate])

    if (error !== undefined) {
        return (<Message type="error" message={error} />);
    }

    if (isLoaded === false) {
        return (<Message type="loading" />);
    }

    let columns = [
        { key: "statusLabel", label: "Status", type: "text" },
        { key: "driverUser", label: "Chauffeur", type: "text" },
        { key: "deliveryRemarks", label: "Opmerkingen voor chauffeur", type: "text" }
    ];

    if (state.status === 'COMPLETED') {
        columns.push({ key: "completeDate", label: "Datum uitgevoerd", type: "date" });
        columns.push({ key: "adminUser", label: "Verwerkt door", type: "date" });
    }

    const content = () => {
        return (
            <>
                {state.deliveryRemarks !== undefined && state.deliveryRemarks !== null &&
                    <Message type="inline-info" message={state.deliveryRemarks} />
                }
                {state.stops.map((stop, i) => (
                    <AppTransportDetailsStop
                        key={"transport-details-stop-" + stop.stopId}
                        stop={stop} index={i}
                        transportStatus={state.status}
                        parents={parents}
                    />
                ))}
            </>
        )
    }

    return (
        <>
            {state.status === "COMPLETED" && state.completeDate !== null &&
                <>
                    <Details
                        columns={columns}
                        data={state}
                        identifier="details"
                    />

                    <div className={'spacer--line'}></div>
                    <ContentWrapper type="title" content="Stops" />
                </>
            }
            <ContentWrapper content={content()} type="page-component"/>
        </>
    );
}