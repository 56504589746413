import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ValidateResponse } from "../helpers/global";
import { fetchStopDetails, processSignature } from "../helpers/transport";

import { AppTransportDetailsArticles } from "./Details/Articles";
import { Message } from "../../components/Message";
import { FormFields } from "../../components/Form/Fields";
import { returnValues, removeValue, validateData } from "../../components/Form/Formfunctions";
import { ContentWrapper } from "../../components/Content/Wrapper";

export function AppTransportSign({ stopId, setPageTitle, parents = "transport/active"}) {

    const location = useLocation();
    const navigate = useNavigate();
    const [state, setState] = useState();
    const [isLoaded, setIsLoaded] = useState(false);
    const [hidden, setHidden] = useState(false);

    const [checkedDamage, setCheckedDamage] = useState(false);
    const [checkedMail, setCheckedMail] = useState(false);

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [error, setError] = useState();
    const [isSubmitted, setSubmitted] = useState(false);
    const currentDate = new Date();
    const documentType = location.state.documentType;
    const notRequired = ['DEPOT-CASE', 'RENTAL-TERMINATION'];

    useEffect(() => {
        fetchStopDetails(stopId).then((res) => {

            const response = ValidateResponse(res.data, 'stop');

            if (response.status === 'error') {
                setError(response.message);
                return;
            }
            else {
                setState(response.stop);
                let pageTitle = response.stop.documents[documentType].typeDisplay + " ondertekenen";
                setPageTitle(pageTitle);
                setIsLoaded(true);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setError(response.message);
            console.log(error);
        })
    }, [setState, stopId, navigate, documentType, setPageTitle])

    if (error !== undefined) {
        return <Message type="error" message={error} />
    }
    if (state === undefined || state.documents === undefined || state.documents === null) {
        return <Message type="noData" />
    }
    if (isLoaded === false) {
        return <Message type="loading" />
    }

    const setInputValues = (key, value, identifier = null) => {

        if (key === 'damage') {
            setCheckedDamage(value);
        }
        if (key === 'mailRequest') {
            setCheckedMail(value);
        }

        // reset errors
        if (isSubmitted === true) {
            setSubmitted(false);
        }
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }

        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const showHide = () => {
        setHidden(!hidden);
    }

    const getLabels = (stop) => {
        let labels = [];

        if (typeof stop.productIN === 'object' && stop.productIN.length > 0 && typeof stop.productOUT === 'object' && stop.productOUT.length > 0) {
            labels['date'] = 'Ophaaldatum/Afleverdatum';
            labels['signature'] = 'both';
        }
        else if (typeof stop.productIN === 'object' && stop.productIN.length > 0) {
            labels['date'] = 'Ophaaldatum';
            labels['signature'] = 'pickup';
        } else if (typeof stop.productOUT === 'object' && stop.productOUT.length > 0) {
            labels['date'] = 'Afleverdatum';
            labels['signature'] = 'dropoff';
        }
        return labels;
    }

    // Set fields
    let rules = notRequired.includes(documentType) ? [] : ["required"];
    let marker = notRequired.includes(documentType) ? "" : "*";

    let labels = getLabels(state);

    let defaultValues = {
        signDate: currentDate.toISOString().split('T')[0],
        nameClient: state.info.contactName ? state.info.contactName.value : null,
        nameEmployee: state.employee !== undefined ? state.employee.name : null,
        email: state.info.contactEmail ? state.info.contactEmail.value 
            : (state.info.email !== undefined ? state.info.email.value ?? null : null),
        comments: state.combinedRemarks ?? null
    }

    if (Object.keys(values).length === 0) {
        setValues(defaultValues);
    }

    let formFields = {
        signDate: {
            type: "date",
            label: labels.date + marker,
            options: { min: "-1", max: "+1" },
            value: values['signDate'],
            rules: rules
        }
    }

    if (labels.signature !== 'dropoff') {
        formFields.nameEmployee = {
            type: "text",
            label: "In ontvangst genomen door (medewerker)" + marker,
            value: values['nameEmployee'],
            rules: rules
        }
    }

    if (labels.signature !== 'pickup' || checkedDamage === true || documentType === 'DEPOT-CASE') {
        formFields.nameClient = {
            type: "text",
            label: "Naam klant" + marker,
            value: values['nameClient'],
            rules: rules
        }
    }

    formFields.mailRequest = {
        type: "checkbox",
        label: state.documents[documentType].typeDisplay + " mailen"
    }

    if (checkedMail === true) {
        formFields.email = {
            type: "text",
            label: "E-mail voor ontvangstbevestiging" + marker,
            value: values['email'],
            rules: rules.concat(['email'])
        }
    }

    if (labels.signature !== 'dropoff') {
        formFields.damage = {
            type: "checkbox",
            label: "Beschadigingen"
        }
        formFields.comments = {
            type: "textarea",
            label: "Opmerkingen"
        }
    }

    if (labels.signature !== 'dropoff') {
        formFields.sigEmployee = {
            type: "signature",
            label: "Handtekening medewerker" + marker,
            rules: rules
        }
    }

    if (labels.signature !== 'pickup' || checkedDamage === true) {
        formFields.sigClient = {
            type: "signature",
            label: "Handtekening klant" + marker,
            rules: rules
        }
    }

    // Submit (after set )
    const handleSubmit = () => {

        setSubmitted(true);

        let validation = validateData(formFields, values, true);

        if (validation.status !== 'success') {
            setErrors(validation.errors);
            return;
        }

        if ((values.sigEmployee !== undefined && values.sigEmployee !== null)
            && (values.nameEmployee === undefined || values.nameEmployee === null || values.nameEmployee === "")) {
            setErrors({nameEmployee:"Naam medewerker mist"});
            return;
        }
        if ((values.sigClient !== undefined && values.sigClient !== null)
            && (values.nameClient === undefined || values.nameClient === null || values.nameClient === "")) {
            setErrors({nameClient:"Naam medewerker mist"});
            return;
        }

        if ((values.sigEmployee === undefined || values.sigEmployee === null)
            && (values.damage !== undefined && values.damage === true)) {
            setErrors({sigEmployee:"Handtekening is verplicht bij beschadigingen"});
            return;
        }
        if ((values.sigClient === undefined || values.sigClient === null)
            && (values.damage !== undefined && values.damage === true)) {
            setErrors({sigClient:"Handtekening is verplicht bij beschadigingen"});
            return;
        }

        const formData = {
            'documentType': documentType,
            'stopId': stopId,
            'nameEmployee': values.nameEmployee,
            'nameClient': values.nameClient,
            'email': values.email,
            'signatureEmployee': values.sigEmployee,
            'signatureClient': values.sigClient,
            'comments': values.comments,
            'damage': values.damage,
            'mailRequest': values.mailRequest,
            'date': values.signDate,
        }

        let globalErrorMessage = "Er is is een fout opgetreden met genereren/mailen van de "
            + state.documents[documentType].typeDisplay.toLowerCase();

        let globalSuccessMessage = null;

        processSignature(formData).then((res) => {
            let message;
            let documentTypes = []

            if (state.documents != null) {
                Object.keys(state.documents).forEach((option) => {
                    if (state.documents[option].isSigned === false) {
                        documentTypes.push(state.documents[option])
                    }
                });
            }

            let response = ValidateResponse(res.data, 'response');

            // @todo: pagina "documenten" mag weg -> niet relevant want de knoppen staan ook bij de details
            // wel rood maken indien verplicht
            if (response.status === 'success') {
                message = response.message ?? globalSuccessMessage;
            } else {
                message = response.message ?? globalErrorMessage;
            }
            navigate("/" + parents + "/details/" + state.transportId,
                { replace: true, state: { message: message, type: response.status } });

        }).catch((error) => {
            console.log(error);
            navigate("/" + parents + "/details/" + state.transportId,
                { replace: true, state: { message: globalErrorMessage, type: "error" } })
        })
    }

    const content = () => {
        return (
            <div className="outline-boxes">
                <div className="component">
                    <div className="outline-box outline-box--details" data-match>
                        <h4 className={"details-link details-link--toggle details-link--toggle" + (hidden ? "--active" : "") + " mt-0"} onClick={(e) => showHide()}>
                            {state.stopName}
                        </h4>
                        {hidden === false && (
                            <div className="article-list-plain">
                                <AppTransportDetailsArticles stop={state} />
                            </div>
                        )}
                    </div>
                </div>

                {/* Document text (contract) */}
                {state.documents[documentType].article != null &&
                    <div className="component">
                        <div className="outline-box outline-box--details" data-match>
                            <span className="d-flex flex-column">
                                <h4>{state.documents[documentType].article.title}</h4>
                            </span>
                            <span className="d-flex flex-column" dangerouslySetInnerHTML={{ __html: state.documents[documentType].article.content }}>
                            </span>
                        </div>
                    </div>
                }

                {/* Form */}
                <div className="component">
                    <div className="outline-box outline-box--details" data-match>

                        <FormFields
                            formFields={formFields}
                            setValues={setInputValues}
                            setErrors={setInputErrors}
                            formValues={values}
                            formErrors={errors}
                            //identifier = null
                            isSubmitted={isSubmitted}
                        />

                        {/* form buttons */}
                        <div className="form__row form__row--buttons">
                            <div className="form__component">
                                <div className="form__element">
                                    <button onClick={() => { handleSubmit() }} className={"button button--primary submit"}>Opslaan</button>
                                </div>
                            </div>
                            <div className="form__component">
                                <div className="form__element">
                                    <Link to={"/" + parents + "/details/" + state.transportId} className={"button button--outline"}>
                                        <span>Annuleren</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    // Render

    return (
        <ContentWrapper content={content()} type="page-component" />
        // <div>
        //     <div className="main">
        //         <div className="main__left"></div>
        //         <div className="main__right">
        //             <div className="components">
        //                 <div className="container">
        //                     <div className="row justify-content-center">
        //                         <div className="col-12">


        //                                 {contentRender()}

        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    );

}