import React from "react";
import { Navigate } from "react-router-dom";

// Segment global
import { RoutesHome } from "../../routes/Home"
import { RoutesError } from "../../routes/Error"
import { RoutesScan } from "../../routes/Scan"

// Segment transport
import { RoutesTransportOverview } from "../../routes/Transport/Overview"
import { RoutesTransportDetails } from "../../routes/Transport/Details"
import { RoutesTransportProcess } from "../../routes/Transport/Process"
import { RoutesTransportSign } from "../../routes/Transport/Sign"
import { RoutesTransportDocuments } from "../../routes/Transport/Documents"

// Segment products
import { RoutesProductsOverviewIntern } from "../../routes/Products/Overview/Intern"
import { RoutesProductsOverviewExtern } from "../../routes/Products/Overview/Extern"
import { RoutesProductsDetails } from "../../routes/Products/Details"
import { RoutesProductsDetailsActions } from "../../routes/Products/Details/Actions"
import { RoutesProductsDetailsAddPhoto } from "../../routes/Products/Details/Addphoto"
import { RoutesProductsDetailsDocuments } from "../../routes/Products/Details/Documents"
import { RoutesProductsDetailsActionsScan } from "../../routes/Products/Details/Actions/Scan"
import { RoutesProductsDetailsInspections } from "../../routes/Products/Details/Inspections"
import { RoutesProductsDetailsInspectionsCreate } from "../../routes/Products/Details/Inspections/Create"

// Segment Ad dossier
import { RoutesAddossiersSearchcustomer } from "../../routes/Addossiers/Searchcustomer"
import { RoutesAddossiersDetails } from "../../routes/Addossiers/Details"
import { RoutesAddossiersDeliver } from "../../routes/Addossiers/Deliver"
import { RoutesAddossiersReturn } from "../../routes/Addossiers/Return"
import { RoutesAddossiersChange } from "../../routes/Addossiers/Change"

// Inspections
import { RoutesInspectionsOverview } from "../../routes/Inspections/Overview"
import { RoutesInspectionsArchive } from "../../routes/Inspections/Archive"
import { RoutesInspectionsProcess } from "../../routes/Inspections/Process"
import { RoutesInspectionsActions } from "../../routes/Inspections/Actions"
import { RoutesInspectionsActionsAddarticle } from "../../routes/Inspections/Actions/Addarticle"

export const routes = [
    {
        path: "*",
        redirect: <Navigate to={"/home"} />,
    },
    {
        path: "/home",
        pageTitle: "Home",
        identifier: "intern",
        topMenu: true,
        environment: "intern", // @todo: liever als global var
        component: <RoutesHome />,
    },
    {
        path: "/error",
        pageTitle: "Error",
        component: <RoutesError />
    },
    // Scannen
    {
        path: "/scan",
        topMenu: true,
        pageTitle: "Scan",
        component: <RoutesScan title="Scannen" parents="scan" current="scan" />
    },
    // Segment Transport
    {
        path: "/transport",
        pageTitle: "Transport",
        topMenu: true,
        redirect: <Navigate to={"/transport/active"} />,
        children: [
            {
                path: "/active",
                //tab: true, aangepast @see Page/Tabs
                tabGroup: "transport-overview",
                pageTitle: "Ingepland",
                component: <RoutesTransportOverview title="Ingeplande transporten" parents="transport" current="active" />
            },
            {
                path: "/active/details/:transportId",
                pageTitle: "Transport details",
                component: <RoutesTransportDetails title="Transport details" parents="transport/active" current="details" />
            },
            {
                path: "/active/process/:stopId",
                pageTitle: "Transport verwerken",
                component: <RoutesTransportProcess title="Transport verwerken" parents="transport/active" current="process" />
            },
            {
                path: "/active/sign/:stopId",
                pageTitle: "Document ondertekenen",
                component: <RoutesTransportSign title="Document ondertekenen" parents="transport/active" current="sign" />
            },
            {
                path: "/active/documents/:stopId",
                pageTitle: "Te ondertekenen documenten",
                component: <RoutesTransportDocuments title="Te ondertekenen documenten" parents="transport/active" current="documents" />
            },
            // Archive
            {
                path: "/archive",
                //tab: true, aangepast @see Page/Tabs
                tabGroup: "transport-overview",
                pageTitle: "Archief",
                component: <RoutesTransportOverview title="Archief transporten" parents="transport" current="archive" />
            },
            {
                path: "/archive/details/:transportId",
                pageTitle: "Transport details",
                component: <RoutesTransportDetails title="Transport details" parents="transport/archive" current="details" />
            },
            {
                path: "/archive/sign/:stopId",
                pageTitle: "Document ondertekenen",
                component: <RoutesTransportSign title="Document ondertekenen" parents="transport/archive" current="sign" />
            },
            {
                path: "/archive/documents/:stopId",
                pageTitle: "Te ondertekenen documenten",
                component: <RoutesTransportDocuments title="Te ondertekenen documenten" parents="transport/archive" current="documents" />
            },
        ]
    },
    // Segment AD dosssiers
    {
        path: "/addossiers",
        pageTitle: "Ad dossiers",
        topMenu: true,
        redirect: <Navigate to={"/addossiers/searchcustomer"} />,
        children: [
            {
                path: "/searchcustomer",
                pageTitle: "Klant zoeken",
                component: <RoutesAddossiersSearchcustomer title="Klant zoeken" parents="addossiers" current="searchcustomer" />
            },
            {
                path: "/details/:userId",
                //tab: true, aangepast @see Page/Tabs
                tabGroup: "addossiers",
                pageTitle: "Details",
                component: <RoutesAddossiersDetails title="Details" parents="addossiers" current="details" />
            },
            {
                path: "/deliver/:userId",
                //tab: true, aangepast @see Page/Tabs
                tabGroup: "addossiers",
                pageTitle: "Afleveren",
                component: <RoutesAddossiersDeliver title="Afleveren" parents="addossiers" current="deliver" />
            }
            // // DEPRECATED 2024-11-25
            // {
            //     path: "/return/:userId",
            //     //tab: true, aangepast @see Page/Tabs
            //     tabGroup: "addossiers",
            //     pageTitle: "Ophalen",
            //     component: <RoutesAddossiersReturn title="Ophalen" parents="addossiers" current="return" />
            // },
            // {
            //     path: "/change/:userId",
            //     //tab: true, aangepast @see Page/Tabs
            //     tabGroup: "addossiers",
            //     pageTitle: "Wissel",
            //     component: <RoutesAddossiersChange title="Wissel" parents="addossiers" current="change" />
            // }
        ]
    },
    // Segment Artikelbeheer
    {
        path: "/products",
        pageTitle: "Artikelbeheer",
        topMenu: true,
        redirect: <Navigate to={"/products/intern"} />,
        children: [
            {
                path: "/intern",
                tabGroup: "products",
                pageTitle: "Intern",
                component: <RoutesProductsOverviewIntern title="Interne artikelen" parents="products" current="intern" />
            },
            {
                path: "/extern",
                tabGroup: "products",
                pageTitle: "Extern",
                component: <RoutesProductsOverviewExtern title="Externe artikelen" parents="products" current="extern" />
            },
            //intern
            {
                path: "/intern/actions/:productId",
                tabGroup: "detailsintern",
                pageTitle: "Acties",
                component: <RoutesProductsDetailsActions parents="products/intern" current="actions" />
            },
            {
                path: "/intern/details/:productId",
                tabGroup: "detailsintern",
                pageTitle: "Details",
                component: <RoutesProductsDetails parents="products/intern" current="details" />
            },
            {
                path: "/intern/photos/:productId",
                tabGroup: "detailsintern",
                pageTitle: "Documenten",
                component: <RoutesProductsDetailsDocuments parents="products/intern" current="photos" />
            },
            {
                path: "/intern/inspections/:productId",
                tabGroup: "detailsintern",
                pageTitle: "Keuringen",
                component: <RoutesProductsDetailsInspections parents="products/intern" current="inspections" />
            },
            {
                path: "/intern/inspections/create/:productId",
                pageTitle: "Keuringen",
                component: <RoutesProductsDetailsInspectionsCreate parents="products/intern/inspections" current="create" />
            },
            {
                path: "/intern/addphoto/:productId",
                pageTitle: "Foto toevoegen",
                component: <RoutesProductsDetailsAddPhoto parents="products/intern" current="addphoto" />
            },
            {
                path: "/intern/scan/:productId/",
                pageTitle: "Scan",
                component: <RoutesProductsDetailsActionsScan parents="products/intern/actions" current="scan" />
            },
            // extern
            {
                path: "/extern/details/actions/:productId",
                tabGroup: "detailsextern",
                pageTitle: "Acties",
                component: <RoutesProductsDetailsActions parents="products/extern" current="actions" />
            },
            {
                path: "/extern/details/:productId",
                tabGroup: "detailsextern",
                pageTitle: "Details",
                component: <RoutesProductsDetails parents="products/extern" current="details" />
            },
            {
                path: "/extern/photos/:productId",
                tabGroup: "detailsextern",
                pageTitle: "Documenten",
                component: <RoutesProductsDetailsDocuments parents="products/extern" current="photos" />
            },
            {
                path: "/extern/inspections/:productId",
                tabGroup: "detailsextern",
                pageTitle: "Keuringen",
                component: <RoutesProductsDetailsInspections parents="products/extern" current="inspections" />
            },
            {
                path: "/extern/inspections/create/:productId",
                pageTitle: "Keuringen",
                component: <RoutesProductsDetailsInspectionsCreate parents="products/extern/inspections" current="create" />
            },
            {
                path: "/extern/addphoto/:productId",
                pageTitle: "Foto toevoegen",
                component: <RoutesProductsDetailsAddPhoto parents="products/extern" current="addphoto" />
            },
            {
                path: "/extern/scan/:productId/",
                pageTitle: "Scan",
                component: <RoutesProductsDetailsActionsScan parents="products/extern/actions" current="scan" />
            },
        ]
    },
    //  Keuringen
    {
        path: "/inspections",
        pageTitle: "Keuringen",
        topMenu: true,
        redirect: <Navigate to={"/inspections/overview"} />,
        children: [
            {
                path: "/actions",
                pageTitle: "Acties",
                tabGroup: "inspections",
                component: <RoutesInspectionsActions title="Acties" parents="inspections" current="actions" />
            },
            {
                path: "/overview",
                pageTitle: "Te keuren",
                tabGroup: "inspections",
                component: <RoutesInspectionsOverview title="Te keuren" parents="inspections" current="overview" />
            },
            {
                path: "/archive",
                pageTitle: "Archief",
                tabGroup: "inspections",
                component: <RoutesInspectionsArchive title="Archief" parents="inspections" current="archive" />
            },
            {
                path: "/process/:inspectionId",
                pageTitle: "Formulier keuring",
                component: <RoutesInspectionsProcess title="Formulier keuring" parents="inspections" current="process" />
            },
            {
                path: "/addarticle",
                pageTitle: "Hulpmiddel toevoegen",
                component: <RoutesInspectionsActionsAddarticle title="Hulpmiddel toevoegen" parents="inspections" current="addarticle" />
            },
            
        ]
    },
    // Redirects
    { path: "/login", redirect: <Navigate to={"/auth/login"} /> },
    { path: "/logout", redirect: <Navigate to={"/auth/logout"} /> },
]