import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ValidateResponse } from "../../../app/helpers/global";
import { fetchCustomer, getRentableSerialnumber } from "../../../app/helpers/addossiers";
import { List } from "../../../components/List";
import { FormFields } from "../../../components/Form/Fields";
import { FormInputSubmit } from "../../../components/Form/Input/Submit";
import { returnValues, removeValue, validateData } from "../../../components/Form/Formfunctions";
import { ContentWrapper } from "../../../components/Content/Wrapper";


export function AppAddossiersSelectSerialnumber({ 
    singleMode = false, // process directly on single add or fill a liset
    userId, 
    setGlobalMessage, 
    processSelect, 
    productId, // alleen bij wissel is undefined bij deliver ()
    title ="Zoek serienummer",
    cancel 
}) {

    const [productsList, setProductsList] = useState([]);
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState();
    const [customer, setCustomer] = useState();
    const [customerAddress, setCustomerAddress] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        fetchCustomer(userId).then((res) => {
            const response = ValidateResponse(res.data, 'customer');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            } else {
                setCustomer(response.customer);
                
                if(response.customer.addresses !== undefined && typeof response.customer.addresses === 'object') {
                    let addressKeys = Object.keys(response.customer.addresses);
                    if (addressKeys.length === 1) {
                        setValues({addressId:addressKeys[0]});
                        setCustomerAddress(addressKeys[0]);
                    }
                }
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message);
        })
    }, [setCustomer, setCustomerAddress, navigate, setGlobalMessage, userId]);



    let formFields = {}

    formFields.serialNumber = {
        type: "text",
        label: "Serienummer *",
        value: values['serialNumber'],
        rules: ["required"]
    }

    if (customer !== undefined) {
        
        formFields.addressId = {
            type: "select",
            label: "Afleveradres *",
            options: customer.addresses,
            value: customerAddress,
            rules: ["required"]   
        }
    }

    const validateSerialNumber = () => {

        setGlobalMessage(null);

        let processType = 'REGISTER';
        let validation = validateData(formFields, values, true);
        let data = values;

        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        const formData = {
            'userId': userId,
            'serialNumber': data.serialNumber,
            'type': processType
        }

        getRentableSerialnumber(formData).then((res) => {
            const response = ValidateResponse(res.data, 'dossier');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            } else {
                let exists = false;

                productsList.forEach((item, index) => {
                    if (item.productId === response.dossier.productId) {
                        exists = true
                    }
                })

                if (productId !== undefined && productsList.length > 0) {
                    setGlobalMessage('U kunt maar één product selecteren om te wisselen', 'error'); // deze kan niet meer voorkomen
                } 
                else if (exists === true) {
                    setGlobalMessage('Hulpmiddel is al in opgenomen in de lijst', 'error');
                }
                else {
                    if (singleMode === true) {
                        processSelect(response.dossier);
                    }
                    else {
                        var newList = [...productsList, response.dossier];
                        setProductsList(newList);
                        document.querySelector(`#serialNumber-form-input`).value = ""
                    }
                }
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message, 'error');
        })
    }

    const submitList = () => {

        let toValidate = {addressId: formFields.addressId}
        let validation = validateData(toValidate, values, true);

        if (validation.status !== 'success') {
            setErrors(validation.errors);
            let globalMessage = validation.errors.globalMessage ?? 'Het formulier kan niet verzonden worden';
            setGlobalMessage(globalMessage, 'error');
            return;
        }

        let productIds = [];
        Object.keys(productsList).forEach((item, index) => {
            productIds = [...productIds, productsList[item].productId]
        })

        let formData = {
            'userId': userId,
            'products': productIds,
            'addressId': values.addressId
        }

        processSelect(formData);
    }

    const removeProduct = (item) => {
        var newList = [...productsList];
        newList.splice(item, 1);
        setProductsList(newList);
    }

    let columns = [
        { key: "remove", type: "button", function: removeProduct, icon: "remove", className: "col-2" },
        { key: "name", label: "Naam", type: "text" },
        { key: "serialNumber", label: "Serienummer", type: "text" }
    ];

    const setInputValues = (key, value, identifier = null) => {
        if (errors) {
            setErrors(removeValue(errors, key, identifier));
        }
        setValues(returnValues(values, key, value, identifier));
    }

    const setInputErrors = (key, value, identifier = null) => {

        // add or remove error
        let newErrors = errors;
        if (value !== undefined && value !== null) {
            newErrors = returnValues(errors, key, value, identifier);
        }
        else {
            newErrors = removeValue(errors, key, identifier);
        }
        setErrors(newErrors);
    }

    const contentValidate = () => {
        return (
            <>
                <FormFields
                    formFields={formFields}
                    setValues={setInputValues}
                    setErrors={setInputErrors}
                    formValues={values}
                    formErrors={errors}
                />

                <FormInputSubmit handleSubmit={validateSerialNumber} submitLabel="Toevoegen" cancelFunction={cancel} />
            </>
        )
    }

    return (<div>

        <ContentWrapper type="title" content={title} />
        <ContentWrapper type='outline-box' content={contentValidate()} />

        {Object.keys(productsList).length > 0 &&


            <ContentWrapper
                type="title-button"
                content="Hulpmiddelen afleveren"
                params={{ label: "Bevestigen", type: "submit" }}
                handleFunction={submitList}
            />
        }

        {Object.keys(productsList).length > 0 &&
            <List
                columns={columns}
                data={productsList}
                identifier="return"
            />
        }
    </div>);
}