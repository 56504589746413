import { Navigate, useLocation } from "react-router-dom";

export function Message({ message, type, title }) {

    const location = useLocation();
    if (type === 'state') {
        message = (location.state !== null ? location.state.message : null);
        type = (location.state != null ? location.state.type : null);
    }

    if (message === "SESSION_EXPIRED") {
        return <Navigate to={"/auth/logout"} 
            state={{ message: "Sessie is verlopen", type: "error" }} />
    }
    else if (message === "FORBIDDEN") {
        return <Navigate to={"/noaccess"} 
            state={{ message: "U heeft geen toegang tot deze pagina", type: "error" }} />
    }

    let boxClass = " outline-box";

    // Default messages
    if (type === 'loading' || type === 'downloading') {

        let reload = true;
        if (message === undefined) {
            message = 'De data wordt opgehaald.';
        }
        if (type === 'downloading') {
            reload = false;
            message = 'Download wordt opgehaald'
        }

        return (
            <>
                {/* <div className="loader-background"></div> */}
                <div className="loader fade-in" >
                    <div className="loader-wrapper outline-box">
                        <div className="loader-text">{message}</div>
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        {reload === true &&
                            <div className="loader-text"><a href={window.location.href}>Pagina opnieuw laden</a></div>
                        }
                    </div>
                </div>
            </>
        );
    }
    else if (type === 'pageNotfound') {
        message = 'De pagina die u probeert te openen is niet gevonden';
    }
    else if (type === 'noData') {
        message = 'Er zijn geen resultaten gevonden';
    }
    // Message translations
    if (message === 'genaral_error_api') {
        message = 'Er is iets misgegaan met het ophalen van de data, probeer het later nog een keer.';
    }

    if (type !== undefined && type !== null) {
        boxClass += " outline-box--" + type;
    }


    if (type === 'inline-error' && message !== undefined && message !== null) {
        return (
            <div className="component">
                <div className={"text-component outline-box outline-box--error"}>
                    {title &&
                        <h3>{title}</h3>
                    }
                    <span>{message}</span>
                </div>
            </div>
        );
    }
    if (type === 'inline-info' && message !== undefined && message !== null) {
        return (
            <div className="component">
                <div className={"text-component outline-box outline-box--info"}>
                    {title &&
                        <h3>{title}</h3>
                    }
                    <span>{message}</span>
                </div>
            </div>
        );
    }

    return <>
        {message !== undefined && message !== null && message !== "" &&
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="component">
                            <br />
                            <div className={"text-component" + boxClass}>
                                {title &&
                                    <h3>{title}</h3>
                                }
                                <span>{message}</span>
                            </div>
                        </div>
                    </div>
                    <div className={'spacer'}></div>
                </div>
            </div>
        }
    </>
}