import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { ValidateResponse } from "../../app/helpers/global";
import { fetchCustomer, fetchAddossiersCustomer } from "../../app/helpers/addossiers";
import { Details } from "../../components/Details"
import { ContentWrapper } from "../../components/Content/Wrapper";
import { List } from "../../components/List";
import { Message } from "../../components/Message";
import { ContentDownload } from "../../components/Content/Download";


export function AppAddossiersDetails({ current, setGlobalMessage }) {

    const { userId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [result] = useState((location.state !== null ? location.state.result : null));
    const [customer, setCustomer] = useState();
    const [dossiers, setDossiers] = useState([]);


    useEffect(() => {
        fetchCustomer(userId).then((res) => {
            const response = ValidateResponse(res.data, 'customer');

            if (response.status === 'error') {
                setGlobalMessage(response.message, response.status);
            } else {
                setCustomer(response.customer);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message);
        })
    }, [setCustomer, navigate, setGlobalMessage, userId])

    useEffect(() => {
        let callData = {
            'userId': userId,
        }
        fetchAddossiersCustomer(callData).then((res) => {
            const response = ValidateResponse(res.data, 'dossiers');
            if (res.data != null) {
                setDossiers(response.dossiers);
            }
        }).catch((error) => {
            let response = ValidateResponse(error);
            setGlobalMessage(response.message);
        })
    }, [setDossiers, navigate, userId])

    let columns = [
        { key: "fullName", label: "Naam", type: "text" },
        { key: "birthDate", label: "Geboortedatum", type: "text" },
        { key: "address", label: "Adres", type: "text" },
        { key: "debtorNumber", label: "Debiteurnummer", type: "text" },
    ];

    if(customer !== undefined) {
        Object.values(customer.addresses).forEach((address) => {
            columns.push({ key: "address" + address.addressId, label: " ", type: "text", value:address})
        });
    }

    let columnsDossiers = [
        { key: "name", label: "Naam", type: "text" },
        { key: "serialNumber", label: "Serienummer", type: "text" },
        {
            key: "documentNumber",
            label: "Download document",
            type: "download",
            display: "icon-download",
            documentType: "documentType",
            mimeType: "mimeType",
            fileName: "fileName",
            documentName: "documentName"
        }
    ];

    const resultMessage = () => {
        if (result !== undefined && result !== null && result.message !== undefined) {
            return (
                <div>
                    <div style={{ float: "left", textAlign: "left", paddingTop: "9px", paddingBottom: "9px" }}>
                        {result.message}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        {result.documentId !== undefined &&
                            <ContentDownload
                                documentType={result.documentType}
                                display={"download-button"}
                                documentId={result.documentNumber}
                                fileName={result.fileName}
                                mimeType={result.mimeType}
                                label={"Download " + result.documentName}
                            />
                        }
                    </div>
                </div>
            );
        }
    }

    const showResult = () => {
        if (result !== undefined && result !== null && result.message !== undefined) {
            return <Message type={result.status} message={resultMessage()} />
        }
        return <></>
    }

    return (<>
        {current === 'details' && customer !== undefined &&
            <>

                {showResult()}

                <ContentWrapper type="title" content={customer.fullName} />

                <Details columns={columns} data={customer} />

                <ContentWrapper type="title" content="Hulpmiddelen in uitleen" />

                <List
                    columns={columnsDossiers}
                    data={dossiers}
                    identifier="return"
                />
                {/* 
		{(dossiers == undefined || dossiers.length == 0) &&
		<ContentWrapper type="outline-box" content="Geen hulpmiddelen in uitleen gevonden" />
		} */}
            </>
        }
    </>
    );

}